<template id="template">
  <div :class="disableBtn && currentStep === 0 ? 'container-dynamic-form-detail' : 'container-dynamic-form'"
    ref="template"">

    <div v-for=" element in getCurrentStep" v-bind:key="uniqueId('root')"
    :class="element.styles + `${verifyConditions(element) ? '' : ' d-none'}`">
    <template v-if="!hideTitle">
      <h1 v-if="verifyConditions(element) && element.type === 'title'" class="text-[#5C5C5C]">
        {{ element.title }}
      </h1>

      <h2 v-if="verifyConditions(element) && element.type === 'subtitle'" class="mt-10  w-max text-[#5C5C5C]">
        {{ element.title }}
      </h2>
    </template>

    <BaseCheckbox :options="element.checkOptions" v-if="verifyConditions(element) && element.type === 'check'"
      :label="element.title" :showAll="element.showAll" @input="(values) => onChangeCheck(element, values)" />

    <BaseInput v-if="verifyConditions(element) && element.type === 'inputText'" :label="element.title"
      :value="FormData[element.keyVariable]" :numeric="element.typeInput === 'number'"
      @input="(value) => onInput(element, value)" @blur="(e, val) => onBlur(e, val, element.keyVariable)"
      :disabled="disableBtn ? true : element.disabled" :required="element.required" :ref="element.keyVariable" />

    <BaseInput v-if="verifyConditions(element) && element.type === 'inputDate'" :label="element.title"
      v-model="FormData[element.keyVariable]" @input="(value) => onInput(element, value)"
      :disabled="disableBtn ? true : element.idHide !== '' ? !!openAccord[element.keyVariable] : element.disabled"
      :required="element.required" type="date" :ref="element.keyVariable" />

    <BaseTextarea v-if="verifyConditions(element) && element.type === 'textArea'" :label="element.title"
      :value="FormData[element.keyVariable]" :disabled="disableBtn ? true : element.disabled"
      @input="(value) => onInput(element, value)" :required="element.required" :maxlength="element.maxlength"
      :ref="element.keyVariable" />

    <BaseSelect v-if="verifyConditions(element) && element.type === 'inputSelect'" :label="element.title"
      v-model="FormData[element.keyVariable]" :disabled="disableBtn ? true : element.disabled"
      @change="(value) => onChangeSelect(element, value)" :required="element.required"
      :selectOptions="getOptions(element.selectOptions)" :id="element.keyVariable" :ref="element.keyVariable" />

    <BaseMultiSelect v-if="verifyConditions(element) && element.type === 'inputMultiSelect'" :title="element.title"
      :data="getOptions(element.selectOptions)" label="name"
      @notify-objects="(values) => onChangeMultiSelect(element, values)" :valuesSelected="FormData[element.keyVariable]"
      :ref="element.keyVariable" :required="element.required" />


    <BaseRadio v-if="verifyConditions(element) && element.type === 'inputOption'" :label="element.title"
      v-model="FormData[element.keyVariable]" :disabled="disableBtn ? true : element.disabled"
      :required="element.required" :radioOptions="getOptions(element.radioOptions)" :ref="element.keyVariable"
      @onChangeValue="(value) => onChangeRadio(element, value)" />

    <DragDrop v-if="verifyConditions(element) && element.type === 'inputFile'" :label="element.title"
      buttonLabel="SELECCIONE SUS ARCHIVOS" :disabled="disableBtn"
      :fileList="FormData[element.keyVariable] != null ? FormData[element.keyVariable] : []" :id="element.keyVariable"
      v-model="FormData[element.keyVariable]" @files-handler="setFiles($event, element.keyVariable)"
      :maxMB="element.maxMB || 20" :accept="element.accept" :extensions="element.extensions"
      :maxMBPerFile="element.maxMBPerFile" :ref="element.keyVariable" />

    <Accordion :open="element.idHide !== '' ? !!openAccord[element.keyVariable] : true"
      :disable="element.idHide === '' ? false : !openAccord[element.keyVariable]" :key="element.keyVariable"
      v-if="verifyConditions(element) && element.type === 'accordion'" :header="element.title"
      :isDelete="element.isDelete" @deletecomponent="deleteComponent(element)">

      <div v-for="element2 in element.contentAccordion" v-bind:key="uniqueId('root-accordion')" :class="element2.styles"
        :ref="element.keyVariable">

        <h1 v-if="verifyConditions(element2) && element2.type === 'title'" class="text-[#5C5C5C]">
          {{ element2.title }}
        </h1>

        <h2 v-if="verifyConditions(element2) && element2.type === 'subtitle'" class="mt-10  w-max text-[#5C5C5C]">
          {{ element2.title }}
        </h2>

        <BaseInput v-if="verifyConditions(element2) && element2.type === 'inputText'" :label="element2.title"
          :value="FormData[element2.keyVariable]" @input="(value) => onInput(element2, value)"
          @blur="(e, val) => onBlur(e, val, element2.keyVariable)" :disabled="disableBtn ? true : element2.disabled"
          :numeric="element2.typeInput === 'number'" :required="element2.required" :ref="element2.keyVariable" />

        <BaseInput v-if="verifyConditions(element2) && element2.type === 'inputDate'" :label="element2.title"
          v-model="FormData[element2.keyVariable]" :disabled="disableBtn ? true : element2.disabled"
          @input="(value) => onInput(element2, value)" :required="element2.required" type="date"
          :ref="element2.keyVariable" />

        <BaseCheckbox :options="element2.checkOptions" v-if="verifyConditions(element2) && element2.type === 'check'"
          :showAll="element2.showAll" :label="element2.title" @input="(values) => onChangeCheck(element2, values)" />

        <BaseTextarea v-if="verifyConditions(element2) && element2.type === 'textArea'" :label="element2.title"
          :value="FormData[element2.keyVariable]" @input="(value) => onInput(element2, value)"
          :disabled="disableBtn ? true : element2.disabled" :required="element2.required"
          :maxlength="element2.maxlength" :ref="element2.keyVariable" />

        <BaseSelect v-if="verifyConditions(element2) && element2.type === 'inputSelect'" :label="element2.title"
          v-model="FormData[element2.keyVariable]" :disabled="disableBtn ? true : element2.disabled"
          @change="(value) => onChangeSelect(element2, value)" :required="element2.required"
          :selectOptions="getOptions(element2.selectOptions)" :id="element.keyVariable" :ref="element2.keyVariable" />

        <BaseMultiSelect v-if="verifyConditions(element2) && element2.type === 'inputMultiSelect'"
          :title="element2.title" :data="getOptions(element2.selectOptions)" label="name"
          @notify-objects="(values) => onChangeMultiSelect(element2, values)"
          :disabled="disableBtn ? true : element2.disabled" :valuesSelected="FormData[element2.keyVariable]"
          :ref="element2.keyVariable" :required="element2.required" />

        <BaseRadio v-if="verifyConditions(element2) && element2.type === 'inputOption'" :label="element2.title"
          v-model="FormData[element2.keyVariable]" :disabled="disableBtn ? true : element2.disabled"
          :required="element2.required" :radioOptions="getOptions(element2.radioOptions)"
          @onChangeValue="(value) => onChangeRadio(element2, value)" :ref="element2.keyVariable" />

        <DragDrop v-if="verifyConditions(element2) && element2.type === 'inputFile'" :label="element2.title"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          :fileList="FormData[element2.keyVariable] != null ? FormData[element2.keyVariable] : []"
          :id="element2.keyVariable" v-model="FormData[element2.keyVariable]" :disabled="disableBtn"
          :accept="element2.accept" :extensions="element2.extensions" :maxMBPerFile="element2.maxMBPerFile"
          @files-handler="setFiles($event, element2.keyVariable)" :maxMB="element2.maxMB || 20"
          :ref="element2.keyVariable" />

        <Accordion v-if="verifyConditions(element2) && element2.type === 'accordion'" :key="element2.keyVariable"
          :open="element2.idHide !== '' ? !!openAccord[element2.keyVariable] : true"
          :disable="element2.idHide === '' ? false : !openAccord[element2.keyVariable]" :header="element2.title"
          :ref="element2.keyVariable" :isDelete="element2.isDelete" @deletecomponent="deleteComponent(element2)">

          <div v-for="element3 in element2.contentAccordion" v-bind:key="uniqueId('root-accordion-sub')"
            :class="element3.styles">
            <h1 v-if="verifyConditions(element3) && element3.type === 'title'" class="text-[#5C5C5C]">
              {{ element3.title }}
            </h1>

            <h2 v-if="verifyConditions(element3) && element3.type === 'subtitle'
    " class="mt-10 w-max text-[#5C5C5C]">
              {{ element3.title }}
            </h2>

            <a-table v-if="verifyConditions(element3) && element3.type === 'table'" :columns="columns"
              :data-source="FormData[element3.keyVariable]" :pagination="false" :scroll="{ x: '1220px' }"
              :ref="element3.keyVariable">
              <div slot="fecha_reporte" class="title-size font-bold text-gray-500 text-center ">
                <p>Producto</p>
              </div>
              <div slot="entidad_vigilada" class="title-size font-bold text-gray-500">
                N°
              </div>
            </a-table>

            <BaseCheckbox :options="element3.checkOptions" :showAll="element3.showAll"
              v-if="verifyConditions(element3) && element3.type === 'check'" :label="element3.title"
              @input="(values) => onChangeCheck(element3, values)" :ref="element3.keyVariable" />

            <BaseInput v-if="verifyConditions(element3) && element3.type === 'inputText'
    " :label="element3.title" :numeric="element3.typeInput === 'number'" :value="FormData[element3.keyVariable]"
              @input="(value) => onInput(element3, value)" @blur="(e, val) => onBlur(e, val, element3.keyVariable)"
              :disabled="disableBtn ? true : element3.disabled" :required="element3.required"
              :ref="element3.keyVariable" />

            <BaseInput v-if="verifyConditions(element3) && element3.type === 'inputDate'
    " :label="element3.title" v-model="FormData[element3.keyVariable]" @input="(value) => onInput(element3, value)"
              :disabled="disableBtn ? true : element3.disabled" :required="element3.required" type="date"
              :ref="element3.keyVariable" />

            <BaseTextarea v-if="verifyConditions(element3) && element3.type === 'textArea'
    " :label="element3.title" :value="FormData[element3.keyVariable]" @input="(value) => onInput(element3, value)"
              :disabled="disableBtn ? true : element3.disabled" :required="element3.required"
              :maxlength="element3.maxlength" :ref="element3.keyVariable" />

            <BaseSelect v-if="verifyConditions(element3) && element3.type === 'inputSelect'
    " :label="element3.title" v-model="FormData[element3.keyVariable]"
              :disabled="disableBtn ? true : element3.disabled" :required="element3.required"
              @change="(value) => onChangeSelect(element3, value)" :selectOptions="getOptions(element3.selectOptions)"
              :ref="element3.keyVariable" />


            <BaseMultiSelect v-if="verifyConditions(element3) && element3.type === 'inputMultiSelect'"
              :title="element3.title" :data="getOptions(element3.selectOptions)" label="name"
              @notify-objects="(values) => onChangeMultiSelect(element3, values)"
              :disabled="disableBtn ? true : element3.disabled" :valuesSelected="FormData[element3.keyVariable]"
              :ref="element3.keyVariable" :required="element3.required" />

            <BaseRadio v-if="verifyConditions(element3) && element3.type === 'inputOption'
    " :label="element3.title" v-model="FormData[element3.keyVariable]"
              :disabled="disableBtn ? true : element3.disabled" :required="element3.required"
              :radioOptions="getOptions(element3.radioOptions)" :ref="element3.keyVariable"
              @onChangeValue="(value) => onChangeRadio(element3, value)" />

            <DragDrop v-if="verifyConditions(element3) && element3.type === 'inputFile'
    " :label="element3.title" buttonLabel="SELECCIONE SUS ARCHIVOS"
              :fileList="FormData[element3.keyVariable] != null ? FormData[element3.keyVariable] : []"
              v-model="FormData[element3.keyVariable]" :id="element3.keyVariable" :disabled="disableBtn"
              :accept="element3.accept" :extensions="element3.extensions" :maxMBPerFile="element3.maxMBPerFile"
              @files-handler="setFiles($event, element3.keyVariable)" :maxMB="element3.maxMB || 20"
              :ref="element3.keyVariable" />



            <a-button v-if="verifyConditions(element3) && element3.type === 'inputBtn'" :disabled="disableBtn"
              class="align-middle flex p-5 text-[#5C5C5C]" style="width: 203%;height: 52px;"
              v-on:click="addComponent(element3)">

              <a-icon type="plus-circle" theme="filled" style="font-size: 30px;margin-top: -10px;color: #3b82f6;" />

              {{ element3.title }}</a-button>

            <Accordion :open="element3.idHide !== '' ? !!openAccord[element3.keyVariable] : true" v-if="verifyConditions(element3) && element3.type === 'accordion'
    " :header="element3.title" :key="element3.keyVariable" :ref="element3.keyVariable"
              :disable="element3.idHide === '' ? false : !openAccord[element3.keyVariable]"
              :isDelete="element3.isDelete" @deletecomponent="deleteComponent(element3)">

              <div v-for="element4 in element3.contentAccordion" v-bind:key="uniqueId('root-accordion-sub-element')"
                :class="element4.styles">
                <h1 v-if="verifyConditions(element4) && element4.type === 'title'">
                  {{ element4.title }}
                </h1>

                <h2 v-if="verifyConditions(element4) && element4.type === 'subtitle'
    " class="mt-10  w-full">
                  {{ element4.title }}
                </h2>


                <BaseCheckbox :options="element4.checkOptions" :showAll="element4.showAll"
                  v-if="verifyConditions(element4) && element4.type === 'check'" :label="element4.title"
                  @input="(values) => onChangeCheck(element4, values)" />

                <BaseInput v-if="verifyConditions(element4) && element4.type === 'inputText'" :label="element4.title"
                  :disabled="disableBtn ? true : element4.disabled" :numeric="element4.typeInput === 'number'"
                  @blur="(e, val) => onBlur(e, val, element4.keyVariable)" :value="FormData[element4.keyVariable]"
                  @input="(value) => onInput(element4, value)" :required="element4.required"
                  :ref="element4.keyVariable" />

                <BaseInput v-if="verifyConditions(element4) && element4.type === 'inputDate'
    " :label="element4.title" v-model="FormData[element4.keyVariable]" @input="(value) => onInput(element4, value)"
                  :disabled="disableBtn ? true : element4.disabled" :required="element4.required" type="date"
                  :ref="element4.keyVariable" />

                <BaseTextarea v-if="verifyConditions(element4) && element4.type === 'textArea'
    " :label="element4.title" :value="FormData[element4.keyVariable]" @input="(value) => onInput(element4, value)"
                  :disabled="disableBtn ? true : element4.disabled" :required="element4.required"
                  :maxlength="element4.maxlength" :ref="element4.keyVariable" />

                <BaseSelect v-if="verifyConditions(element4) && element4.type === 'inputSelect'
    " :label="element4.title" v-model="FormData[element4.keyVariable]"
                  :disabled="disableBtn ? true : element4.disabled" :required="element4.required"
                  @change="(value) => onChangeSelect(element4, value)"
                  :selectOptions="getOptions(element4.selectOptions)" :ref="element4.keyVariable" />

                <BaseMultiSelect v-if="verifyConditions(element4) && element4.type === 'inputMultiSelect'"
                  :title="element4.title" :data="getOptions(element4.selectOptions)" label="name"
                  @notify-objects="(values) => onChangeMultiSelect(element4, values)"
                  :disabled="disableBtn ? true : element4.disabled" :valuesSelected="FormData[element4.keyVariable]"
                  :ref="element4.keyVariable" :required="element4.required" />

                <BaseRadio v-if="verifyConditions(element4) && element4.type === 'inputOption'
    " :label="element4.title" v-model="FormData[element4.keyVariable]"
                  :disabled="disableBtn ? true : element4.disabled" :required="element4.required"
                  :radioOptions="getOptions(element4.radioOptions)" :ref="element4.keyVariable"
                  @onChangeValue="(value) => onChangeRadio(element4, value)" />

                <DragDrop v-if="verifyConditions(element4) && element4.type === 'inputFile'
    " :label="element4.title" buttonLabel="SELECCIONE SUS ARCHIVOS"
                  :fileList="FormData[element4.keyVariable] != null ? FormData[element4.keyVariable] : []"
                  v-model="FormData[element4.keyVariable]" :id="element4.keyVariable" :disabled="disableBtn"
                  :accept="element4.accept" :extensions="element4.extensions" :maxMBPerFile="element4.maxMBPerFile"
                  @files-handler="setFiles($event, element4.keyVariable)" :maxMB="element4.maxMB || 20"
                  :ref="element4.keyVariable" />

                <a-button v-if="verifyConditions(element4) && element4.type === 'inputBtn'" :disabled="disableBtn"
                  class="align-middle flex p-5 text-[#5C5C5C]" style="width: 203%;height: 52px;"
                  v-on:click="addComponent(element4)">

                  <a-icon type="plus-circle" theme="filled" style="font-size: 30px;margin-top: -10px;color: #3b82f6;" />

                  {{ element4.title }}</a-button>

                <Accordion :open="element4.idHide !== '' ? !!openAccord[element4.keyVariable] : true" v-if="verifyConditions(element4) && element4.type === 'accordion'
    " :header="element4.title" :key="element4.keyVariable" :ref="element4.keyVariable"
                  :disable="element4.idHide === '' ? false : !openAccord[element4.keyVariable]"
                  :isDelete="element4.isDelete" @deletecomponent="deleteComponent(element4)">

                  <div v-for="element5 in element4.contentAccordion"
                    v-bind:key="uniqueId('root-accordion-sub-element-sub-')" :class="element5.styles">
                    <h1 v-if="verifyConditions(element5) && element5.type === 'title'">
                      {{ element5.title }}
                    </h1>

                    <h2 v-if="verifyConditions(element5) && element5.type === 'subtitle'
    " class="mt-10  w-full">
                      {{ element5.title }}
                    </h2>

                    <BaseCheckbox :options="element5.checkOptions" :showAll="element5.showAll"
                      v-if="verifyConditions(element5) && element5.type === 'check'" :label="element5.title"
                      @input="(values) => onChangeCheck(element5, values)" />

                    <BaseInput v-if="verifyConditions(element5) && element5.type === 'inputText'"
                      :label="element5.title" :value="FormData[element5.keyVariable]"
                      @input="(value) => onInput(element5, value)" :numeric="element5.typeInput === 'number'"
                      :disabled="disableBtn ? true : element5.disabled" :required="element5.required"
                      :ref="element5.keyVariable" @blur="(e, val) => onBlur(e, val, element5.keyVariable)" />

                    <BaseInput v-if="verifyConditions(element5) && element5.type === 'inputDate'
    " :label="element5.title" v-model="FormData[element5.keyVariable]" @input="(value) => onInput(element5, value)"
                      :disabled="disableBtn ? true : element5.disabled" :required="element5.required" type="date"
                      :ref="element5.keyVariable" />

                    <BaseTextarea v-if="verifyConditions(element5) && element5.type === 'textArea'"
                      :label="element5.title" :value="FormData[element5.keyVariable]"
                      @input="(value) => onInput(element5, value)" :disabled="disableBtn ? true : element5.disabled"
                      :required="element5.required" :maxlength="element5.maxlength" :ref="element5.keyVariable" />

                    <BaseSelect v-if="verifyConditions(element5) && element5.type === 'inputSelect'"
                      :label="element5.title" v-model="FormData[element5.keyVariable]"
                      @change="(value) => onChangeSelect(element5, value)"
                      :disabled="disableBtn ? true : element5.disabled" :required="element5.required"
                      :selectOptions="getOptions(element5.selectOptions)" :ref="element5.keyVariable" />

                    <BaseMultiSelect v-if="verifyConditions(element5) && element5.type === 'inputMultiSelect'"
                      :title="element5.title" :data="getOptions(element5.selectOptions)" label="name"
                      @notify-objects="(values) => onChangeMultiSelect(element5, values)"
                      :disabled="disableBtn ? true : element5.disabled" :valuesSelected="FormData[element5.keyVariable]"
                      :ref="element5.keyVariable" :required="element5.required" />

                    <BaseRadio v-if="verifyConditions(element5) && element5.type === 'inputOption'
    " :label="element5.title" v-model="FormData[element5.keyVariable]"
                      :disabled="disableBtn ? true : element5.disabled" :required="element5.required"
                      :radioOptions="getOptions(element5.radioOptions)" :ref="element5.keyVariable"
                      @onChangeValue="(value) => onChangeRadio(element5, value)" />

                    <DragDrop v-if="verifyConditions(element5) && element5.type === 'inputFile'
    " :label="element5.title" buttonLabel="SELECCIONE SUS ARCHIVOS"
                      :fileList="FormData[element5.keyVariable] != null ? FormData[element5.keyVariable] : []"
                      v-model="FormData[element5.keyVariable]" :id="element5.keyVariable" :disabled="disableBtn"
                      :accept="element5.accept" :extensions="element5.extensions" :maxMBPerFile="element5.maxMBPerFile"
                      @files-handler="setFiles($event, element5.keyVariable)" :maxMB="element5.maxMB || 20"
                      :ref="element5.keyVariable" />

                    <a-button v-if="verifyConditions(element5) && element5.type === 'inputBtn'" :disabled="disableBtn"
                      class="align-middle flex p-5 text-[#5C5C5C]" style="width: 203%;height: 52px;"
                      v-on:click="addComponent(element5)">

                      <a-icon type="plus-circle" theme="filled"
                        style="font-size: 30px;margin-top: -10px;color: #3b82f6;" />

                      {{ element5.title }}</a-button>

                    <Accordion :open="element5.idHide !== '' ? !!openAccord[element5.keyVariable] : true" v-if="verifyConditions(element5) && element5.type === 'accordion'
    " :header="element5.title" :key="element5.keyVariable" :ref="element5.keyVariable"
                      :disable="element5.idHide === '' ? false : !openAccord[element5.keyVariable]"
                      :isDelete="element5.isDelete" @deletecomponent="deleteComponent(element5)">

                      <div v-for="element6 in element5.contentAccordion"
                        v-bind:key="uniqueId('root-accordion-sub-element-sub-6-')" :class="element6.styles">
                        <h1 v-if="verifyConditions(element6) && element6.type === 'title'">
                          {{ element6.title }}
                        </h1>

                        <h2 v-if="verifyConditions(element6) && element6.type === 'subtitle'
    " class="mt-10  w-full">
                          {{ element6.title }}
                        </h2>

                        <BaseCheckbox :options="element6.checkOptions" :showAll="element6.showAll"
                          v-if="verifyConditions(element6) && element6.type === 'check'" :label="element6.title"
                          @input="(values) => onChangeCheck(element6, values)" />

                        <BaseInput v-if="verifyConditions(element6) && element6.type === 'inputText'"
                          :label="element6.title" :value="FormData[element6.keyVariable]"
                          @input="(value) => onInput(element6, value)" :disabled="disableBtn ? true : element6.disabled"
                          :required="element6.required" :numeric="element6.typeInput === 'number'"
                          :ref="element6.keyVariable" @blur="(e, val) => onBlur(e, val, element6.keyVariable)" />

                        <BaseInput v-if="verifyConditions(element6) && element6.type === 'inputDate'
    " :label="element6.title" v-model="FormData[element6.keyVariable]" @input="(value) => onInput(element6, value)"
                          :disabled="disableBtn ? true : element6.disabled" :required="element6.required" type="date"
                          :ref="element6.keyVariable" />

                        <BaseTextarea v-if="verifyConditions(element6) && element6.type === 'textArea'"
                          :value="FormData[element6.keyVariable]" @input="(value) => onInput(element6, value)"
                          :label="element6.title" :disabled="disableBtn ? true : element6.disabled"
                          :required="element6.required" :maxlength="element6.maxlength" :ref="element6.keyVariable" />

                        <BaseSelect v-if="verifyConditions(element6) && element6.type === 'inputSelect'"
                          :label="element6.title" v-model="FormData[element6.keyVariable]"
                          @change="(value) => onChangeSelect(element6, value)"
                          :disabled="disableBtn ? true : element6.disabled" :required="element6.required"
                          :selectOptions="getOptions(element6.selectOptions)" :ref="element6.keyVariable" />

                        <BaseMultiSelect v-if="verifyConditions(element6) && element6.type === 'inputMultiSelect'"
                          :title="element6.title" :data="getOptions(element6.selectOptions)" label="name"
                          @notify-objects="(values) => onChangeMultiSelect(element6, values)"
                          :disabled="disableBtn ? true : element6.disabled"
                          :valuesSelected="FormData[element6.keyVariable]" :ref="element6.keyVariable"
                          :required="element6.required" />

                        <BaseRadio v-if="verifyConditions(element6) && element6.type === 'inputOption'
    " :label="element6.title" v-model="FormData[element6.keyVariable]"
                          :disabled="disableBtn ? true : element6.disabled" :required="element6.required"
                          :radioOptions="getOptions(element6.radioOptions)" :ref="element6.keyVariable"
                          @onChangeValue="(value) => onChangeRadio(element6, value)" />

                        <DragDrop v-if="verifyConditions(element6) && element6.type === 'inputFile'
    " :label="element6.title" buttonLabel="SELECCIONE SUS ARCHIVOS"
                          :fileList="FormData[element6.keyVariable] != null ? FormData[element6.keyVariable] : []"
                          v-model="FormData[element6.keyVariable]" :id="element6.keyVariable" :disabled="disableBtn"
                          :accept="element6.accept" :extensions="element6.extensions"
                          :maxMBPerFile="element6.maxMBPerFile" @files-handler="setFiles($event, element6.keyVariable)"
                          :maxMB="element6.maxMB || 20" :ref="element6.keyVariable" />

                        <a-button v-if="verifyConditions(element6) && element6.type === 'inputBtn'"
                          :disabled="disableBtn" class="align-middle flex p-5 text-[#5C5C5C]"
                          style="width: 203%;height: 52px;" v-on:click="addComponent(element6)">

                          <a-icon type="plus-circle" theme="filled"
                            style="font-size: 30px;margin-top: -10px;color: #3b82f6;" />

                          {{ element6.title }}</a-button>

                      </div>
                    </Accordion>

                  </div>
                </Accordion>

              </div>
            </Accordion>
          </div>
        </Accordion>
      </div>
    </Accordion>
  </div>
  <div class="w-100 d-flex mt-1 items-center justify-center">
    <button v-if="currentStep === 0 && !disableBtn" class="btn btn-primary-outline ms-auto me-1"
      v-on:click="cancelForm">
      CANCELAR
    </button>
    <button v-if="currentStep >= 1 && !disableBtn" class="btn btn-primary-outline ms-auto me-1" v-on:click="backStep">
      VOLVER
    </button>
    <button v-if="currentStep < (totalSteps) && !disableBtn" class="btn btn-primary ms-1 me-auto" v-on:click="nextStep">
      SIGUIENTE
    </button>
    <button class="btn btn-primary-outline me-1 mt-10" v-on:click="cancelDetail"
      v-if="disableBtn && (currentStep) >= 1">
      VOLVER
    </button>
    <button v-if="currentStep === (totalSteps) && !disableBtn" class="btn btn-primary ms-1 me-auto" v-on:click="() => {
    if (withConfirmation) {
      showModal = true
    } else {
      this.submitForm()
    }
  }">
      {{ viewConfig.textButtonSubmit }}
    </button>

    <Modal v-if="showModal">
      <div class="w-full px-4 flex justify-end items-center">
        <a-icon type="plus" class="text-2xl ml-auto transform text-gray-600 rotate-45"
          @click.native="showModal = false" />
      </div>
      <div class="pb-4 flex flex-col justify-center items-center">
        <div class="mb-4 w-full max-w-md text-left">
          <h2 class="mb-2 text-2xl text-blue-dark">
            <b>
              <slot name="modalTitle" />
            </b>
          </h2>
          <slot name="modalContent" />
        </div>

        <div class="w-full max-w-md flex flex-col gap-4">
          <a-button type="primary" :block="true" shape="round" @click.native="submitForm">
            {{ viewConfig.textButtonSubmit }}
          </a-button>
          <slot name="modalAdditionalButton" :formData="responseJson" :closeModal="closeModal">

          </slot>
        </div>
      </div>
    </Modal>

  </div>
  </div>
</template>

<style>
.container-dynamic-form {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
}

.container-dynamic-form-detail {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.mt-10 {
  margin-top: 30px;
}

.p-1 {
  padding: 10px;
}

.accordion>div {
  margin-top: 15px;
  width: 100%;
}

.accordion>div>div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.h1 {
  font-weight: bold;
  font-size: 1.7em;
}

.h2 {
  font-weight: bold;
  font-size: 1.4em;
}

.me-50 {
  margin-right: 50%;
}

.ms-1 {
  margin-left: 10px;
}

.mt-1 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.me-1 {
  margin-right: 10px;
}

.ms-auto {
  margin-left: auto;
}

.me-auto {
  margin-right: auto;
}

.btn {
  border-radius: 20px;
  padding: 10px 70px;
  border: 2px solid;
  font-weight: bold;
  width: 350px;
}

.color-primary,
.color-primary>h1,
.color-primary>h2,
.color-primary>p,
.color-primary>small,
.color-primary>i {
  color: #00549f;
}

.color-gray,
.color-gray>h1,
.color-gray>h2,
.color-gray>p,
.color-gray>small,
.color-gray>i {
  color: #444444;
}

.btn-primary {
  background: #00549f;
  border-color: #00549f;
  color: white;
}

.btn-primary-outline {
  background: white;
  border-color: #00549f;
  color: #00549f;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.d-flex {
  display: flex;
}
</style>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import mockupRegister from "@/assets/registrationAnnualReport.json";
import mockupReport from "@/assets/mockup.json";
import templateMotivo from "@/assets/template_motivo.json";
import { get, has, uniqueId, set, debounce, isEmpty, isEqual } from 'lodash'
import moment from "moment";

const columns = [
  {
    slots: { title: "Producto" },
    dataIndex: "producto",
    key: "producto",
    width: 80,
  },
  {
    slots: { title: "n" },
    dataIndex: "n",
    key: "n",
    width: 80,
  },
]

export default {
  props: [
    "urlform",
    "initForm",
    "disable",
    "step",
    "disableBtn",
    "isReport",
    "hideTitle",
    "withConfirmation",
    "initialResponse"
  ],
  components: {
    Accordion,
  },
  data() {
    return {
      showModal: false,
      files: [],
      dataRowsProducto: [],
      keyValidatorC: {},
      activateNext: {},
      totalSteps: 1,
      currentStep: 0,
      FormData: {},
      responseJson: {
        "fecha_reporte": moment().format('DD/MM/YYYY hh:mm A'),
        "responsable": "",
        "tiempo": "Óptimo",
        "estado": "Borrador",
        "steps": {},
        "entidad_vigilada": "",
      },
      viewConfig: {},
      openAccord: {},
      optionsReasons: [],
      optionsProducts: [], columns: columns,
    };
  },
  created() {
    this.getForm();

    this.dataRowsProducto = [{

      key: "row.document_id",
      producto: 'produc',
      n: "row.entidad_vigilada",
    }]

    if (this.initialResponse) {
      this.responseJson = {
        ...this.responseJson,
        ...this.initialResponse,
      }
    }

    this.currentStep = this.step ?? 0;

    this.getInitialDta()

    if (this.initForm != null && this.isReport && !this.disableBtn) {
      this.responseJson = { ...Object.assign({}, this.initForm) };
      this.onChangeSelect({
        onChange: "setProductAndReasons",
      }, this.responseJson["cod_entidad_vigilada"])
      this.FormData = { ...Object.assign({}, this.initForm['steps'][this.currentStep]) }
    } else if (this.initForm != null && !this.isReport && !this.disableBtn) {
      this.responseJson = { ...Object.assign({}, this.initForm) };
      this.FormData = { ...Object.assign({}, this.initForm['steps'][this.currentStep]) }
    } else {
      this.FormData = { ...Object.assign({}, this.initForm) }
    }
  },
  mounted() {
    this.generateJson();
    this.pointChange();
  },
  methods: {
    async getInitialDta() {
      const first_name = await this.$store.state.session?.user?.first_name;
      const last_name = await this.$store.state.session?.user?.last_name;

      this.responseJson["responsable"] = `${first_name} ${last_name}`;
    },
    uniqueId,
    closeModal() {
      this.showModal = false
    },
    onInput(element, value) {
      this.FormData = Object.assign(this.FormData, { [element.keyVariable]: value })
      const that = this;

      this.$nextTick(() => {
        that.generateJson();
        that.pointChange(element);
      })
    },
    onBlur(e, val, key) {
      this.FormData[key] = val
      this.$forceUpdate();
    },
    validate(element) {
      if (!this.FormData[element.keyVariable] && element.required) {
        if (this.$refs[element.keyVariable]) {
          this.$refs[element.keyVariable][0].setInvalid(true);
          return false;
        }
      }
      return true;
    },
    getNameById(lista, idBuscado, defaultValue = null) {
      try {
        const elementoEncontrado = lista.find((elemento) => String(elemento.id) === idBuscado);
        if (elementoEncontrado) {
          return elementoEncontrado.name;
        }
      } catch (error) {
      }
      return defaultValue;
    },
    async pointChange(element) {

      if (typeof element === "object" && !this.disableBtn) {

        if (element.keyVariableHide == "step0-map3-keyperiodoDeRegistro") {
          this.FormData["step0-map3-keyperiodoDeRegistro"] = moment().format('YYYY-MM-DD')
        }

        if (element.keyVariable == 'step2-map1_0-keyLaDcf' && this.FormData['step2-map1_0-keyLaDcf'] == 'No' && this.currentStep == 2 && !this.activateNext['step2-map1_0-keyLaDcf']) {
          this.nextStep();
          this.activateNext['step2-map1_0-keyLaDcf'] = true
        }

        if (element.keyVariable == 'step3-map1_2-keyLaDcf' && this.FormData['step3-map1_2-keyLaDcf'] == 'No' && this.currentStep == 3 && !this.activateNext['step3-map1_2-keyLaDcf']) {
          this.nextStep();
          this.activateNext['step3-map1_2-keyLaDcf'] = true
        }

        if (element.keyVariable == 'step3.3.0-map1_7_0-keyLaDcf' && this.FormData['step3.3.0-map1_7_0-keyLaDcf'] == 'No' && this.currentStep == 3 && !this.activateNext['step3.3.0-map1_7_0-keyLaDcf']) {
          this.nextStep();
          this.activateNext['step3.3.0-map1_7_0-keyLaDcf'] = true
        }

        if (element.keyVariable == 'step1-map1_3_18-keyElDcf' && this.FormData['step1-map1_3_18-keyElDcf'] == 'No' && this.currentStep == 1 && !this.activateNext['step1-map1_3_18-keyElDcf']) {
          this.nextStep();
          this.activateNext['step1-map1_3_18-keyElDcf'] = true
        }

        if (element.keyVariable == 'step1-map1_3.2.4_18_1-keyElDcf' && this.FormData['step1-map1_3.2.4_18_1-keyElDcf'] == 'No' && this.currentStep == 1 && !this.activateNext['step1-map1_3.2.4_18_1-keyElDcf']) {
          this.nextStep();
          this.activateNext['step1-map1_3.2.4_18_1-keyElDcf'] = true
        }

        if ((element.keyVariable?.includes('keyproducto')) && this.FormData[element.keyVariable]) {

          let data = await this.getCounters(this.FormData[element.keyVariable], null, element.params);

          this.$notification.close('success_producto');

          this.$notification.success({
            key: 'success_producto',
            message: "Codigo de producto: " + this.FormData[element.keyVariable],
            description: "Total producto: " + data?.products_count?.toString(),
          });
        }

        if ((element.keyVariable?.includes('keymotivo')) && this.FormData[element.keyVariable]) {

          let data = await this.getCounters(null, this.FormData[element.keyVariable], element.params);

          this.$notification.close('success_motivo');

          this.$notification.success({
            key: 'success_motivo',
            message: "Codigo de motivo: " + this.FormData[element.keyVariable],
            description: "Total motivo: " + data?.reasons_count?.toString(),
          });

        }

        //table step 1.3.2.3 checks
        if (element.keyVariable == "step1-map1_3_16-keyLosPronunciamientos" && this.FormData["step1-map1_3_16-keyLosPronunciamientos"] && this.currentStep == 1) {

          let motivos = [];
          for (const key in this.FormData) {
            //PRODUCTO
            if (key.includes("step1-map1_2_20_0-12")) {
              console.log('incliye producto', key)
              console.log('incliye producto', this.FormData[key])
            }
            //MOTIVO
            if ((key.includes("step1-map1_2_20_2"))) {

              for (const keyOptions in this.optionsReasons) {

                for (const keyFD in this.FormData[key]) {

                  if (this.optionsReasons[keyOptions].id === this.FormData[key][keyFD]) {
                    console.log('option', keyOptions)
                    motivos.push(this.optionsReasons[keyOptions].name)
                    console.log('keyoptions', this.optionsReasons[keyOptions].name)

                  }
                }
              }
            }
            //QUEJAS DEL MOTIVO
            if ((key.includes("step1-map1_2_20_3"))) {
              console.log('incliye quejas motivo', key)
              console.log('queja motivo', this.FormData[key])
            }
            // QUEJAS DEL PRODUCTO
            if ((key.includes("step1-map1_2_20_3"))) {
              console.log('step1-map1_2_20_1', key)
              console.log('queja prod', this.FormData[key])

            }
          }

          this.FormData["step1-map1_3_16-allProducts"] = motivos.map((row) => {
            return {
              key: row,
              producto: row,
              n: "row.entidad_vigilada",
            };
          });
          
          console.log('data', this.FormData["step1-map1_3_16-allProducts"])

        }



        if ((element.keyVariable?.includes('keyNumeroDeQuejasProducto')) && this.FormData[element.keyVariable]) {

          if (!this.keyValidatorC[element.keyValidatorCounter]) {
            this.keyValidatorC[element.keyValidatorCounter] = {}
          }

          this.keyValidatorC[element.keyValidatorCounter][element.keyVariable] = parseInt(this.FormData[element.keyVariable])

          for (const key in this.keyValidatorC) {

            if (key === element.keyValidatorCounter) {
              let counterPM = 0;

              for (const data in this.keyValidatorC[key]) {
                counterPM += parseInt(this.keyValidatorC[key][data]);
              }


              if (element.keyValidatorCounter1 === "step1-map1_1_1-totalProducto") {
                this.FormData[element.keyValidatorCounter1] = counterPM.toString();
              }

              if (parseInt(this.FormData[element.keyValidatorCounter]) !== counterPM) {
                this.$notification.close("fail_pm")
                this.$notification.close("success_pm")


                this.$notification.warning({
                  key: 'fail_pm',
                  message: "No coincide el número registrado por el DCF con los productos/motivos gestionados"
                })
              } else {
                this.$notification.close("success_pm")
                this.$notification.close("fail_pm")


                this.$notification.success({
                  key: 'success_pm',
                  message: "Coincide el número registrado por el DCF con los productos/motivos gestionados"
                })
              }
            }
          }
        }


        /*   if ((element.keyVariable.includes('keyNumeroDeQuejasMotivo')) && this.FormData[element.keyVariable] && this.currentStep == 1) {
   
             if (!this.keyValidatorC[element.keyValidatorCounter]) {
               this.keyValidatorC[element.keyValidatorCounter] = {}
             }
   
             this.keyValidatorC[element.keyValidatorCounter][element.keyVariable] = parseInt(this.FormData[element.keyVariable])
   
   
             // for (const key in this.keyValidatorC) {
   
             //   if (key === element.keyValidatorCounter) {
             //     let counterPM = 0;
   
             //     for (const data in this.keyValidatorC[key]) {
             //       counterPM += parseInt(this.keyValidatorC[key][data]);
             //     }
             //     if (parseInt(this.FormData[element.keyValidatorCounter]) !== counterPM) {
             //       this.$notification.warning({
             //         message: "No coincide el número registrado por el DCF con los productos/motivos gestionados"
             //       })
             //     } else {
             //       this.$notification.success({
             //         message: "Coincide el número registrado por el DCF con los productos/motivos gestionados"
             //       })
             //     }
             //   }
             // }
   
           }*/
        if (element.keyVariable == 'step1-map1_2_5-tx-key' && this.FormData['step1-map1_2_5-tx-key'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_2_5-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_2_5-key-data-backend'] !== this.FormData['step1-map1_2_5-tx-key']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_1_0-keyNúmeroTotalDe' && this.FormData['step1-map1_1_0-keyNúmeroTotalDe'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_1_0-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_1_0-key-data-backend'] !== this.FormData['step1-map1_1_0-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_2_3-key121' && this.FormData['step1-map1_2_3-key121'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_2_3-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_2_3-key-data-backend'] !== this.FormData['step1-map1_2_3-key121']) {
              this.$notification.close("counter2")
              this.$notification.warning({
                key: 'counter2',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_2_8-key122' && this.FormData['step1-map1_2_3-key121'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_2_8-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_2_8-key-data-backend'] !== this.FormData['step1-map1_2_8-key122']) {
              this.$notification.close("counter2")
              this.$notification.warning({
                key: 'counter2',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_2_13-key123' && this.FormData['step1-map1_2_13-key123'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_2_13-key123key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_2_13-key123key-data-backend'] !== this.FormData['step1-map1_2_13-key123']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_2_19-key124' && this.FormData['step1-map1_2_19-key124'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_2_19-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_2_19-key-data-backend'] !== this.FormData['step1-map1_2_19-key124']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_2_22-keyNúmeroTotalDe' && this.FormData['step1-map1_2_22-keyNúmeroTotalDe'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_2_22-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_2_22-key-data-backend'] !== this.FormData['step1-map1_2_22-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_2_27-keyNúmeroTotalDe' && this.FormData['step1-map1_2_27-keyNúmeroTotalDe'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_2_27-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_2_27-key-data-backend'] !== this.FormData['step1-map1_2_27-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_2_31-keyNúmeroTotalDe' && this.FormData['step1-map1_2_31-keyNúmeroTotalDe'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_2_31-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_2_31-key-data-backend'] !== this.FormData['step1-map1_2_31-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_3_2-keyNúmeroTotalDe' && this.FormData['step1-map1_3_2-keyNúmeroTotalDe'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_3_2-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_3_2-key-data-backend'] !== this.FormData['step1-map1_3_2-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_3_8-keyNúmeroTotalDe' && this.FormData['step1-map1_3_8-keyNúmeroTotalDe'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_3_8-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_3_8-key-data-backend'] !== this.FormData['step1-map1_3_8-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_3_15-keyNúmeroTotalDe' && this.FormData['step1-map1_3_15-keyNúmeroTotalDe'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step1-map1_3_15-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step1-map1_3_15-key-data-backend'] !== this.FormData['step1-map1_3_15-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step1-map1_3_12-keyNúmeroTotalDe' && this.FormData['step1-map1_3_12-keyNúmeroTotalDe'] && this.currentStep == 1) {

          let debounced = debounce(async () => {

            let sum =
              parseInt(this.FormData['step1-map1_2_27-keyNúmeroTotalDe']) +
              parseInt(this.FormData['step1-map1_2_31-keyNúmeroTotalDe'])

            let data = parseInt(this.FormData['step1-map1_3_12-keyNúmeroTotalDe']) ?? 0

            if (sum !== data) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con la sumatoria de los pasos 1.2.5.1 y 1.2.5.2"
              })
            } else {
              this.$notification.close("counter")
              this.$notification.success({
                key: 'counter',
                message: "Coincide el número registrado por el DCF con la sumatoria de los pasos 1.2.5.1 y 1.2.5.2"
              })
            }
          }, 1000)

          debounced();
        }


        if (element.keyVariable == 'step2-map1_1-keyNúmeroTotalDe' && this.FormData['step2-map1_1-keyNúmeroTotalDe'] && this.currentStep == 2) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step2-map1_1-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step2-map1_1-key-data-backend'] !== this.FormData['step2-map1_1-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step2-map1_2_0-keyNúmeroTotalDe' && this.FormData['step2-map1_2_0-keyNúmeroTotalDe'] && this.currentStep == 2) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step2-map1_2_0-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step2-map1_2_0-key-data-backend'] !== this.FormData['step2-map1_2_0-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step2-map1_3_0-keyNúmeroTotalDe' && this.FormData['step2-map1_3_0-keyNúmeroTotalDe'] && this.currentStep == 2) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step2-map1_3_0-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step2-map1_3_0-key-data-backend'] !== this.FormData['step2-map1_3_0-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step2-map1_4_0-keyNúmeroTotalDe' && this.FormData['step2-map1_4_0-keyNúmeroTotalDe'] && this.currentStep == 2) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step2-map1_4_0-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step2-map1_4_0-key-data-backend'] !== this.FormData['step2-map1_4_0-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step3-map1_3-keyNúmeroTotalDe' && this.FormData['step3-map1_3-keyNúmeroTotalDe'] && this.currentStep == 3) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step3-map1_3-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step3-map1_3-key-data-backend'] !== this.FormData['step3-map1_3-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step3-map1_5_1-keyNúmeroTotalDe' && this.FormData['step3-map1_5_1-keyNúmeroTotalDe'] && this.currentStep == 3) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step3-map1_5_1_4_0-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step3-map1_5_1_4_0-key-data-backend'] !== this.FormData['step3-map1_5_1-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step3-map1_6_1-keyNúmeroTotalDe' && this.FormData['step3-map1_6_1-keyNúmeroTotalDe'] && this.currentStep == 3) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step3-map1_6_1_0-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step3-map1_6_1_0-key-data-backend'] !== this.FormData['step3-map1_6_1-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step3-map1_6_7-keyNúmeroTotalDe' && this.FormData['step3-map1_6_7-keyNúmeroTotalDe'] && this.currentStep == 3) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step3-map1_6_7-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step3-map1_6_7-key-data-backend'] !== this.FormData['step3-map1_6_7-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step3-map1_6_12-keyNúmeroTotalDe' && this.FormData['step3-map1_6_12-keyNúmeroTotalDe'] && this.currentStep == 3) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step3-map1_6_12-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step3-map1_6_12-key-data-backend'] !== this.FormData['step3-map1_6_12-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step3-map1_6_17-keyNúmeroTotalDe' && this.FormData['step3-map1_6_17-keyNúmeroTotalDe'] && this.currentStep == 3) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step3-map1_6_17-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step3-map1_6_17-key-data-backend'] !== this.FormData['step3-map1_6_17-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step3-map1_6_22-keyNúmeroTotalDe' && this.FormData['step3-map1_6_22-keyNúmeroTotalDe'] && this.currentStep == 3) {

          let debounced = debounce(async () => {

            let data = await this.getCounters(null, null, element.params);

            this.FormData['step3-map1_6_22-key-data-backend'] = data.complaint_count.toString()

            if (this.FormData['step3-map1_6_22-key-data-backend'] !== this.FormData['step3-map1_6_22-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == 'step3-map1_6_28-keyNúmeroTotalDe' && this.FormData['step3-map1_6_28-keyNúmeroTotalDe'] && this.currentStep == 3) {

          let debounced = debounce(async () => {

            let sum =
              parseInt(this.FormData['step3-map1_6_7-keyNúmeroTotalDe']) +
              parseInt(this.FormData['step3-map1_6_12-keyNúmeroTotalDe']) +
              parseInt(this.FormData['step3-map1_6_17-keyNúmeroTotalDe']) +
              parseInt(this.FormData['step3-map1_6_22-keyNúmeroTotalDe']);


            this.FormData['step3-map1_6_28-key-data-backend'] = sum.toString();

            if (this.FormData['step3-map1_6_28-key-data-backend'] !== this.FormData['step3-map1_6_28-keyNúmeroTotalDe']) {
              this.$notification.close("counter")
              this.$notification.warning({
                key: 'counter',
                message: "No coincide el número registrado por el DCF"
              })
            }
          }, 1000)

          debounced();
        }

        if (element.keyVariable == "step3-map1_6_0-keyLaDcf" && this.FormData["step3-map1_6_0-keyLaDcf"] == "No" && this.currentStep == 3) {


          if (this.FormData["step3-map1_3-keyNúmeroTotalDe"] !== this.FormData["step3-map1_5_1-keyNúmeroTotalDe"]) {
            this.$notification.close("counter")
            this.$notification.success({
              key: 'counter',
              message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
            })
          }

        }


        if (element.keyVariable == 'step1-map1_2_21-key125' && this.FormData['step1-map1_2_21-key125'] == 'No Aplica' && this.currentStep == 1) {

          this.FormData['step1-map1_2_26-keydecisionesDelDcf'] = "Aplica"
        }

        if (element.keyVariable == 'step1-map1_2_21-key125' && this.FormData['step1-map1_2_21-key125'] == 'Aplica' && this.currentStep == 1) {

          delete this.FormData['step1-map1_2_26-keydecisionesDelDcf']
        }

        if (element.keyVariable == 'step1-map1_2_26-keydecisionesDelDcf' && this.FormData['step1-map1_2_26-keydecisionesDelDcf'] == 'No Aplica' && this.currentStep == 1) {

          this.FormData['step1-map1_2_30-keydecisionesDelDcf'] = "Aplica"
        }

        if (element.keyVariable == 'step1-map1_2_26-keydecisionesDelDcf' && this.FormData['step1-map1_2_26-keydecisionesDelDcf'] == 'Aplica' && this.currentStep == 1) {
          delete this.FormData['step1-map1_2_30-keydecisionesDelDcf']
        }

        if (element.keyVariable == 'step1-map1_2_30-keydecisionesDelDcf' && this.FormData['step1-map1_2_30-keydecisionesDelDcf'] == 'No Aplica' && this.currentStep == 1) {

          this.FormData['step1-map1_2_26-keydecisionesDelDcf'] = "Aplica"
        }


        if (element.keyVariable == 'step0-map3-keyfechafin' && this.FormData['step0-map3-keyfechafin'] && this.currentStep == 0) {

          let fechaIni = new Date(this.FormData['step0-map3-keyfechaini']);
          let fechaFin = new Date(this.FormData['step0-map3-keyfechafin']);

          if (fechaFin < fechaIni) {
            this.$notification.warning({
              message: "El período final no puede ser anterior al período inicio"
            })
          }
        }

      }


      if (this.FormData['step0-map0-keyseleccionaEntidadVigilada'] && this.currentStep == 0) {
        this.responseJson["cod_entidad_vigilada"] = this.FormData['step0-map0-keyseleccionaEntidadVigilada']
        this.$set(this.FormData, 'step0-map1-keycodigoDeLa', this.FormData['step0-map0-keyseleccionaEntidadVigilada'])
        this.responseJson["entidad_vigilada"] = this.getNameById(this.formOptions.optionsCompanies, this.FormData['step0-map0-keyseleccionaEntidadVigilada']);
      }

    },
    async getCounters(producto, motivo, query) {
      let json = {
        "entity_code": this.responseJson["cod_entidad_vigilada"],
        "products": producto != null ? [producto] : [],
        "reasons": motivo != null ? motivo : [],
      }
      console.log('json', json)

      let { data } = await this.$api.getCounters(json, query);

      console.log('response', data)
      return data;
    },
    async generateTemplateMotivo(element) {

      const keyMotivoProducto = Math.random().toString(16).slice(2)
      const keyProducto = Math.random().toString(16).slice(2)
      const keyTextA = Math.random().toString(16).slice(2)
      const keyMotivo = Math.random().toString(16).slice(2)
      const keyQuejas = Math.random().toString(16).slice(2)
      const keyTextm = Math.random().toString(16).slice(2)
      const keyQuejas1 = Math.random().toString(16).slice(2)

      templateMotivo["keyVariable"] = keyMotivoProducto;
      templateMotivo["params"] = element.params;


      templateMotivo["contentAccordion"][0]["keyVariable"] = element.keyP ?? "" + keyProducto + '-keyproducto';
      templateMotivo["contentAccordion"][0]["params"] = element.params;

      templateMotivo["contentAccordion"][1]["keyVariable"] = element.keyQuejasP ?? "" + keyQuejas + '-keyNumeroDeQuejasProducto';
      templateMotivo["contentAccordion"][1]["keyValidatorCounter"] = element.keyValidatorCounter;
      templateMotivo["contentAccordion"][1]["keyValidatorCounter1"] = element.keyValidatorCounter1;
      templateMotivo["contentAccordion"][1]["title"] = element.titleP;

      templateMotivo["contentAccordion"][2]["keyVariable"] = keyTextA;
      templateMotivo["contentAccordion"][2]["conditionVisible"] = [{
        "keyTarget": keyProducto + '-keyproducto',
        "objetiveValues": ["0"]
      }] 
      

      templateMotivo["contentAccordion"][3]["keyVariable"] = element.keyM ?? "" + keyMotivo + '-keymotivo';
      templateMotivo["contentAccordion"][3]["params"] = element.params;

      templateMotivo["contentAccordion"][4]["keyVariable"] = element.keyQuejasM ?? "" + keyQuejas1 + '-keyNumeroDeQuejasMotivo';
      templateMotivo["contentAccordion"][4]["title"] = element.titleM;

      templateMotivo["contentAccordion"][5]["keyVariable"] = keyTextm;
      templateMotivo["contentAccordion"][5]["conditionVisible"] = [{
        "keyTarget": keyMotivo + '-keymotivo',
        "objetiveValues": [99]
      }]



      return templateMotivo;
    },
    async addComponent(element) {
      let newData = has(this.viewConfig.steps[this.currentStep], element.addComponentKey)

      if (newData) {

        let template = await this.generateTemplateMotivo(element);

        template["addComponentKey"] = element.addComponentKey;

        const arrayActual = get(this.viewConfig.steps[this.currentStep], element.addComponentKey, []);

        let index = arrayActual.findIndex(x => x === element)

        arrayActual.splice(index, 0, structuredClone(template))

        this.$set(this.viewConfig.steps[this.currentStep], element.addComponentKey, arrayActual);

      }
    },
    deleteComponent(element) {

      const arrayActual = get(this.viewConfig.steps[this.currentStep], element.addComponentKey, []);

      let index = arrayActual.findIndex(x => x === element)

      arrayActual.splice(index, 1)
      const keyProducto = element.contentAccordion[0]["keyVariable"];
      const keyQuejas = element.contentAccordion[1]["keyVariable"];
      const keyTextA = element.contentAccordion[2]["keyVariable"];
      const keyMotivo = element.contentAccordion[3]["keyVariable"];
      const keyQuejas1 = element.contentAccordion[4]["keyVariable"];
      const keyTexm = element.contentAccordion[5]["keyVariable"];

      delete this.FormData[keyProducto];
      delete this.FormData[keyQuejas];
      delete this.FormData[keyTextA];
      delete this.FormData[keyMotivo];
      delete this.FormData[keyQuejas1];
      delete this.FormData[keyTexm];

      this.$set(this.viewConfig.steps[this.currentStep], element.addComponentKey, arrayActual);
    },
    validateForm() {
      let actualStep = (this.currentStep).toString();
      let goToNext = [];

      for (let index = 0; index < this.viewConfig.steps[actualStep].length; index++) {
        let element = this.viewConfig.steps[actualStep][index];
        if (element.contentAccordion) {
          for (let index = 0; index < element.contentAccordion.length; index++) {
            let element2 = element.contentAccordion[index];
            if (element2.contentAccordion) {
              for (let index = 0; index < element2.contentAccordion.length; index++) {
                let element3 = element2.contentAccordion[index];
                if (element3.contentAccordion) {
                  for (let index = 0; index < element3.contentAccordion.length; index++) {
                    let element4 = element3.contentAccordion[index];
                    if (element4.contentAccordion) {
                      for (let index = 0; index < element4.contentAccordion.length; index++) {
                        let element5 = element4.contentAccordion[index];
                        if (element5.contentAccordion) {
                          for (let index = 0; index < element5.contentAccordion.length; index++) {
                            let element6 = element5.contentAccordion[index];
                            const isvalid = this.validate(element6);
                            goToNext.push(isvalid)
                          }
                        } else {
                          const isvalid = this.validate(element5);
                          goToNext.push(isvalid)
                        }
                      }
                    } else {
                      const isvalid = this.validate(element4);
                      goToNext.push(isvalid)
                    }
                  }
                } else {
                  const isvalid = this.validate(element3);
                  goToNext.push(isvalid)
                }
              }
            } else {
              const isvalid = this.validate(element2);
              goToNext.push(isvalid)
            }
          }
        } else {
          const isvalid = this.validate(element);
          goToNext.push(isvalid)
        }
      }

      let isToNext = goToNext.every((value) => {
        return value === true;
      });

      return isToNext;
    },
    onChangeRadio(element, value) {

      const that = this;

      this.pointChange(element);

      this.$nextTick(() => {
        that.generateJson();
      })

      this.openAccord[element.keyVariableHide] = this.open(element)

      if (!!element.onChange) {
        if (element.onChange === "notify") {
          if (value === element.onChangeValue) {
            this.$notification.warning({
              message: element.notifyMessage
            })
          }
        }
      }
    },
    open(element) {
      return (
        !!this.FormData[element.keyVariable]
        && this.FormData[element.keyVariable] !== element.idHide
      )
    },
    generateJson() {
      let actualStep = this.currentStep;

      this.FormData['complete'] = false;


      const lengthFormData = Object.keys(this.FormData).length - 1;

      if (this.viewConfig.steps[actualStep].length === lengthFormData) {
        this.FormData['complete'] = true;
      }

      let convertDataForm = JSON.parse(JSON.stringify(this.FormData))

      this.responseJson['steps'][actualStep] = convertDataForm

      localStorage.setItem(
        "DynamicForm",
        JSON.stringify(this.responseJson)
      );
    },
    async getForm() {
      let data = {};
      if (this.isReport) {

        data = mockupReport;
      } else {
        data = mockupRegister;

      }

      /* if (this.urlform.includes("http")) {
         data = await this.fetchJsonData(this.urlform);
       } else {
 
         let { data: dataPivot, error } = await this.$api.getFormRequest(
           `?id=${this.urlform}`
         );
         data = dataPivot;
 
 
         if (error) {
           return;
         }
       }*/

      function formatearSteps(objeto) {
        // Verifica si el objeto tiene una clave llamada "steps"
        if ("steps" in objeto) {
          const steps = objeto["steps"];
          // Verifica si "steps" es un objeto con claves numéricas
          if (
            typeof steps === "object" &&
            !Array.isArray(steps) &&
            Object.keys(steps).every((key) => !isNaN(Number(key)))
          ) {
            // Convierte "steps" en un array de arrays
            objeto["steps"] = Object.values(steps);
          }
        }
        return objeto;
      }

      const dataKeys = Object.keys(data);
      const dataValues = Object.values(data);


      const isFirebaseFormat = dataKeys.length === 1;


      const dataConfig = isFirebaseFormat
        ? formatearSteps(dataValues[0])
        : data;



      this.viewConfig = {
        ...this.viewConfig,
        ...dataConfig,
      };

      this.totalSteps = this.viewConfig.steps.length - 1;
    },
    async fetchJsonData(url) {
      try {
        const response = await fetch(url);


        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          );
        }

        const data = await response.json();



        return data;
      } catch (error) {
        return {}; // Retorna un objeto vacío en caso de error
      }
    },
    verifyConditions(obj) {
      if (obj.keyVariableHide) {
        this.openAccord[obj.keyVariableHide] = this.open(obj)
      }
      if (obj && obj.conditionVisible) {
        return this.todosSonTrue(
          obj.conditionVisible.map((item) => {
            return this.verificarCoincidencia(
              this.FormData,
              item.keyTarget,
              item.objetiveValues
            );
          })
        );
      } else {
        return true;
      }
    },
    todosSonTrue(array) {
      return array.every(function (element) {
        return element === true;
      });
    },
    verificarCoincidencia(objeto, clave, array) {

      if (objeto.hasOwnProperty(clave)) {
        if (array.includes(objeto[clave])) {
          return true;
        }
      }
      return false;
    },
    nextStep() {

      let isToNext = this.validateForm();


      if (isToNext) {
        this.$nextTick(function () {
          window.scrollTo(0, 0);
        })
        this.generateJson()
        this.pointChange()

        this.FormData = {};
        this.files = []

        localStorage.setItem(
          "DynamicForm",
          JSON.stringify(this.responseJson)
        );


        this.submitForm()

        this.currentStep++;

        if (this.responseJson['steps'][this.currentStep]) {
          this.FormData = Object.assign({}, this.FormData, this.responseJson['steps'][this.currentStep]);
        }
      }

    },
    backStep() {
      this.currentStep--
      this.FormData = Object.assign({}, this.FormData, this.responseJson['steps'][this.currentStep]);
    },
    cancelForm() {
      localStorage.removeItem('DynamicFormID');
      localStorage.removeItem('DynamicForm');
      this.keyValidatorC = {};
      this.$emit("methodCancel", 1);
    },
    cancelDetail() {
      this.$emit("methodCancelDetail");
    },
    scroll() {
      this.$refs.template?.scrollIntoView({ block: "start", inline: "nearest" });
    },
    submitForm() {
      this.showModal = false;

      this.scroll()

      if ((this.currentStep) === 0 && !this.initForm) {
        this.$emit("methodSubmit", this.responseJson);
      }

      if ((this.viewConfig.steps.length - 1) === (this.currentStep)) {

        if (this.initForm != null && this.isReport) {
          this.$notification.success({
            message: "Notificación",
            description: "Informe actualizado de forma exitosa",
          });
          this.$emit("saveDraft", this.responseJson);
        } else if (this.initForm != null && !this.isReport) {
          this.$notification.success({
            message: "Notificación",
            description: "Informe actualizado de forma exitosa",
          });
          this.$emit("saveDraft", this.responseJson);
        } else {
          this.$notification.success({
            message: "Notificación",
            description: "Informe creado de forma exitosa",
          });
          this.$emit("saveDraft", this.responseJson);
        }

        this.cancelForm()

      }

      if ((this.viewConfig.steps.length - 1) !== this.currentStep && (this.currentStep) !== 0) {
        this.$emit("saveDraft", this.responseJson);
      }

    },
    setFiles(files, keyVariable) {
      this.FormData[keyVariable] = files;
      this.$forceUpdate();
      this.$emit("addAnexo", { 'files': files, 'step': this.currentStep, });

    },
    getOptions(key) {
      if (Array.isArray(key)) {
        return key;
      } else {
        switch (key) {
          case "optionsReasons":
            return this.optionsReasons
          case "optionsProducts":
            return this.optionsProducts
          default:
            return this.formOptions[key];
        }
      }
    },
    async onChangeSelect(element, value) {

      const that = this
      this.pointChange(element);
      this.$nextTick(() => {
        that.generateJson();
      })
      switch (element.onChange) {
        case "setProductAndReasons":
          if (!value) {
            break;
          }
          let optionsReasons = await this.$store.dispatch(
            "form/loadReasonsOptions",
            { company: value }
          );
          let optionsProducts = await this.$store.dispatch(
            "form/loadProductsOptions",
            { company: value }
          );

          const otherOption = {
            "id": 0,
            "name": "OTROS"
          }

          optionsProducts.push(otherOption);

          this.optionsReasons = optionsReasons;
          this.optionsProducts = optionsProducts;
          break;
      }
    },
    async onChangeMultiSelect(element, values) {

      let data = values.map((val) => val.id)

      if (!isEmpty(data) && !isEqual(data, this.FormData[element.keyVariable])) {
        const that = this
        this.FormData[element.keyVariable] = data
        this.pointChange(element);
        this.$nextTick(() => {
          that.generateJson();
        })
      }
    },
    async onChangeCheck(element, values) {

      if (!isEmpty(values)) {
        const that = this
        this.FormData[element.keyVariable] = values
        this.pointChange(element);
        this.$nextTick(() => {
          that.generateJson();
        })
      }
    }
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    getCurrentStep() {
      return this.viewConfig.steps[this.currentStep];
    },
  },
  watch: {
    currentStep() {
      this.pointChange()
      this.generateJson()
    }
  }
};
</script>